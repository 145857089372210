import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout/layout";
import Faq from "../components/faq";

const FaqLabs = ({ data }) => (
  <Layout>
    <div className="container-fluid">
      <h1 className="title is-1">
        <center>FAQ on Virtual Labs</center>
      </h1>
    </div>
    <Faq mdxNodes={data.allMdx.nodes} />
  </Layout>
);

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(posts/faqVirtualLabs/)/" } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      nodes {
        frontmatter {
          title
        }
        body
      }
    }
  }
`;

export default FaqLabs;
